import React from "react"
import styled from "styled-components"

const ProcessStepsStyled = styled.ul`
  width: 80%;
`

const processSteps = props => {
  return <ProcessStepsStyled>{props.children}</ProcessStepsStyled>
}

export default processSteps
