import React from "react"
import * as Styled from "./styled"
import { Link } from "gatsby"

import Button from "../UI/Button/Button"
import PageArrow from "../UI/PageArrow/PageArrow"
import Heading from "../UI/Heading/Heading"

const heroImage = props => {
  return (
    <Styled.ImageContainer {...props} id={props.id}>
      <Styled.TextWrapper>
        <Styled.FlexSection>
          <Heading textColor="#A93F55">Hi, I'm Issy.</Heading>
          <Heading minor>
            I'm a frontend developer based in Cardiff. Big fan of React,
            GatsbyJS and my two dogs. Currently working at the awesome{" "}
            <a
              href="https://pirate.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Pirate Studios
            </a>
            .
          </Heading>
          <Link to="/#work">
            <Button>SEE MY WORK</Button>
          </Link>
        </Styled.FlexSection>
      </Styled.TextWrapper>
      <PageArrow link="/#services" />
    </Styled.ImageContainer>
  )
}

export default heroImage
