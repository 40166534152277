import React from "react"
import styled from "styled-components"
import Heading from "../UI/Heading/Heading"

const PortfolioTeaserStyled = styled.div`
  width: 30%;
  height: 300px;
  background: ${props => props.image};
  cursor: pointer;
  background-size: cover;
  background-position: center;
  margin: 20px 0;
  position: relative;

  @media ${props => props.theme.media.tablet} {
    width: 80%;
    margin-top: 20px;
  }
  @media ${props => props.theme.media.mobileL} {
    width: 100%;
  }
`

const OpacityCoverStyled = styled.div`
  background: ${props => props.theme.colors.lightFont};
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${PortfolioTeaserStyled}:hover & {
    opacity: 0.4;
  }

  @media ${props => props.theme.media.mobileL} {
    opacity: 0.2;
  }
`

const CaptionStyled = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.darkFont};
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  text-align: center;
  padding: 0 5%;

  ${PortfolioTeaserStyled}:hover & {
    opacity: 1;
  }

  @media ${props => props.theme.media.mobileL} {
    opacity: 1;
  }
`

const portfolioTeaser = props => {
  return (
    <PortfolioTeaserStyled {...props}>
      <OpacityCoverStyled />
      <CaptionStyled>
        <Heading minor textColor={props => props.theme.colors.lightFont}>
          {props.children}
        </Heading>
      </CaptionStyled>
    </PortfolioTeaserStyled>
  )
}

export default portfolioTeaser
