import React from "react"
import styled, { withTheme } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Heading from "../UI/Heading/Heading"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
//import { faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 50%;

  @media ${props => props.theme.media.tablet} {
    width: 100%;
    align-items: center;
  }
`

const CustomHeading = styled(Heading)`
  text-align: right;
  padding-left: 20px;

  @media ${props => props.theme.media.tablet} {
    text-align: center;
    padding-right: 20px;
  }
  @media ${props => props.theme.media.mobileL} {
    font-size: 1.5em;
  }
`

const ContactInfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${props => props.theme.media.mobileL} {
    flex-direction: column;
  }
`

const contactText = props => {
  return (
    <ContactTextStyled {...props}>
      <ContactInfoContainer>
        <FontAwesomeIcon
          icon={faEnvelope}
          size="3x"
          color={props.theme.colors.lightFont}
        />
        <CustomHeading minor>hello@issydennis.com</CustomHeading>
      </ContactInfoContainer>
      {/* <ContactInfoContainer>
                <FontAwesomeIcon icon={faPhone} size='3x' color={props.theme.colors.primaryAccent} />
                <CustomHeading minor>07780112549</CustomHeading>
            </ContactInfoContainer> */}
    </ContactTextStyled>
  )
}

export default withTheme(contactText)
