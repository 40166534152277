import React from "react"
import styled from "styled-components"

import Heading from "../../UI/Heading/Heading"
import Paragraph from "../../UI/Paragraph/Paragraph"

const ProcessStepStyled = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;

  @media ${props => props.theme.media.mobileL} {
    flex-direction: column;
    text-align: center;
  }
`

const Number = styled(Heading)`
  font-size: 6em;
  padding: 0;
  color: ${props => props.theme.colors.lightFont};
  margin: 0 20px 0 0;
`

const TextContainer = styled.div``

// const ParagraphStyled = styled.p`
//     padding: 5px 0;
//     font-size: 1.2em;

//     @media ${props => props.theme.media.mobileL} {
//         font-size: 1.1em;
//       }
// `;

const processStep = props => {
  return (
    <ProcessStepStyled>
      <Number>{props.number}.</Number>
      <TextContainer>
        <Heading minor>{props.heading}</Heading>
        <Paragraph>{props.children}</Paragraph>
      </TextContainer>
    </ProcessStepStyled>
  )
}

export default processStep
