import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Heading from "../UI/Heading/Heading"
import Paragraph from "../UI/Paragraph/Paragraph"
import { slideFromLeft } from "../../styledComponents/animations"

const AboutBubbleStyled = styled.div`
    background: ${props => props.theme.colors.opacitySecondaryAccent};
    border-radius: 80px;
    width: 35rem;
    padding: 3rem;
    margin-right: 20rem;
    text-align: right;

    ${props =>
      props.animate &&
      css`
        animation: ${slideFromLeft} 1s linear;
        animation-fill-mode: forwards;
      `}
    
    @media ${props => props.theme.media.laptop} {
        margin-right: 15rem;

    }
    @media ${props => props.theme.media.tablet} {
        width: 75%;
        margin-right: 0;
    }
    @media ${props => props.theme.media.mobileL} {
        width: 90%;
        border-radius: 100px;
        padding: 10%;
        text-align: center;
        margin-right: 0;
    }
`

const CustomHeading = styled(Heading)`
  padding: 0;
  color: ${props => props.theme.colors.primaryAccent};
`

// const ParagraphStyled = styled.p`
//     padding: 5px;
//     font-size: 1.5em;

//     @media ${props => props.theme.media.tablet} {
//         font-size: 1.2em;
//     }
//     @media ${props => props.theme.media.mobileL} {
//         font-size: 1.1em;
//       }
// `;

const aboutBubble = props => {
  return (
    <AboutBubbleStyled {...props}>
      <CustomHeading align="right">About</CustomHeading>
      <Heading align="right" minor>
        React developer with a love for beautiful websites
      </Heading>
      <Paragraph>
        Responsive, speedy websites make me happy. I'm a JAMstack enthusiast and spend most of my time working with React, GatsbyJS and TypeScript.
      </Paragraph>
      <Paragraph>
        I'm a self-taught developer so in my spare moments I like to write
        tutorials to help others learn too at{" "}
        <strong>
          <a href="https://www.frontendstumbles.com">frontendstumbles.com</a>
        </strong>
        .
      </Paragraph>
      <Paragraph>
        Feel free to{" "}
        <strong>
          <Link to="/#contact">get in touch</Link>
        </strong>
        .
      </Paragraph>
    </AboutBubbleStyled>
  )
}

export default aboutBubble
