import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

const PageArrowStyled = styled.div`
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  &:hover {
    color: blue;
  }

  @media ${props => props.theme.media.mobileL} {
    bottom: 5%;
  }
`

const IconStyled = styled(FontAwesomeIcon)`
  font-size: 60px;
  color: ${props => props.theme.colors.secondaryAccent};
  &:hover {
    color: ${props => props.theme.colors.darkFont};
  }
  @media ${props => props.theme.media.mobileL} {
    font-size: 40px;
  }
`

const pageArrow = props => {
  return (
    <Link to={props.link}>
      <PageArrowStyled>
        <IconStyled icon={faChevronCircleDown} size="4x" />
      </PageArrowStyled>
    </Link>
  )
}

export default pageArrow
