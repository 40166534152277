import React from "react"
import styled, { withTheme, css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Heading from "../Heading/Heading"
import Paragraph from "../Paragraph/Paragraph"
import { slideFromBottom } from "../../../styledComponents/animations"

const TeaserStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 23%;
    padding: 10px;

    p {
        //font-weight: bold;
        color: ${props => props.theme.colors.lightFont};
        &:hover {
color: ${props => props.theme.colors.primaryAccent};
        }
    }

    ${props =>
      props.animate &&
      css`
        animation: ${slideFromBottom} 1s linear;
        animation-fill-mode: forwards;
      `}

    @media ${props => props.theme.media.tablet} {
        width: 100%;
        margin-bottom: 3%;
    }

`

const IconContainerStyled = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1rem;
`

const teaser = props => {
  return (
    <TeaserStyled {...props}>
      <IconContainerStyled>
        <FontAwesomeIcon
          icon={props.icon}
          size="4x"
          color={props.theme.colors.lightFont}
        />
      </IconContainerStyled>
      <Heading minor>{props.heading}</Heading>
      <Paragraph>{props.children}</Paragraph>
    </TeaserStyled>
  )
}

export default withTheme(teaser)
