import React from "react"
import styled from "styled-components"

import { grow } from "../../../styledComponents/animations"

const ButtonStyled = styled.button`
  border: 3px solid
    ${props =>
      !props.buttonColor
        ? props.theme.colors.primaryAccent
        : props.buttonColor};
  border-radius: 100px;
  background: transparent;
  margin-top: 1.5rem;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${props =>
    !props.buttonColor ? props.theme.colors.primaryAccent : props.buttonColor};
  cursor: pointer;

  &:hover {
    animation: ${grow} 0.2s linear;
    animation-fill-mode: forwards;
  }

  @media ${props => props.theme.media.mobileL} {
    font-size: 1em;
  }
`

const button = props => {
  return <ButtonStyled {...props}>{props.children}</ButtonStyled>
}

export default button
