import { keyframes } from "styled-components"

export const grow = keyframes`
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
`

export const slideFromBottom = keyframes`
from {
    transform: translate(0, 200px);
}

to {
    transform: translate(0, 0);
}
`

export const slideFromLeft = keyframes`
from {
    transform: translate(-500px, 0);
}

to {
    transform: translate(0, 0);
}
`
