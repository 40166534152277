import React, { Component } from "react"

import { theme } from "../styledComponents/theme"
import styled, { ThemeProvider } from "styled-components"
import { GlobalStyle } from "../styledComponents/globalStyles"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import IDHelmet from "../components/IDHelmet/IDHelmet"
import SocialsImage from "../images/id-socials.png"
import throttle from "lodash.throttle"

import HeroImage from "../components/HeroImage/HeroImage"
import Header from "../components/UI/Header/Header"
import PageSection from "../components/PageSection/PageSection"
import Heading from "../components/UI/Heading/Heading"
import Paragraph from "../components/UI/Paragraph/Paragraph"
import FlexContainer from "../components/UI/FlexContainer/FlexContainer"
import Teaser from "../components/UI/Teaser/Teaser"
import AboutBubble from "../components/AboutBubble/AboutBubble"
import PortfolioTeaser from "../components/PorfolioTeaser/PortfolioTeaser"
import ContactText from "../components/ContactText/ContactText"
//import Footer from '../components/UI/Footer/Footer';
import ProcessSteps from "../components/ProcessSteps/ProcessSteps"
import ProcessStep from "../components/ProcessSteps/ProcessStep/ProcessStep"

import BorderControlImage from "../images/border-control-teaser.jpg"
import BlogImage from "../images/frontendstumbles-teaser.jpg"
import CarrotJoeImage from "../images/carrot-joe-teaser.jpg"
import AeroTagImage from "../images/aerotag-teaser.jpg"
import CanineCrazeImage from "../images/canine-craze-teaser.jpg"
import BehaveImage from "../images/behave-teaser.jpg"
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faComment } from "@fortawesome/free-solid-svg-icons"

const CustomHeading = styled(Heading)`
  margin-bottom: 0;
  padding: 0;

  @media ${props => props.theme.media.tablet} {
    margin-bottom: 10px;
  }
`

const AnchorLink = styled.a`
  position: absolute;
  top: -95px;
`

const CustomPageSection = styled(PageSection)`
  padding: 0;
  width: 100%;
`

const CustomBackgroundImage = styled(BackgroundImage)`
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  @media ${props => props.theme.media.tablet} {
    align-items: center;
  }
`

const CustomParagraph = styled(Paragraph)`
  padding: 5px 5px 25px 5px;
  text-align: center;
  font-weight: 500;
  width: 70%;

  @media ${props => props.theme.media.tablet} {
    width: 90%;
  }
`

const Emphasis = styled.strong`
  display: inline;
  font-weight: bold;
  padding; 0;
  margin: 0;
`

// ThemeProvider provides the theme.js to all child components.
// Reset is reset.css as a global style (from a package).
// GlobalStyle injects global styles into the entire project.
// Helmet allows us to define meta data etc.

class IndexPage extends Component {
  state = {
    windowHeight: null,
    atTop: true,
  }

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight })
    window.addEventListener("scroll", throttle(this.handleScroll, 300))
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", throttle(this.handleScroll, 300))
  }

  handleScroll = () => {
    if (window.pageYOffset > 0) {
      this.setState({ atTop: false })
    } else {
      this.setState({ atTop: true })
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <IDHelmet
            title="Issy Dennis - Frontend Developer"
            description="Frontend developer based in Cardiff, specialising in React and Typescript"
            image={SocialsImage}
            url="https://www.issydennis.com"
          />

          <Header id="top" svgBackground={!this.state.atTop} />

          <HeroImage height={this.state.windowHeight} id="hero" />

          <PageSection
            align="center"
            background={props => props.theme.colors.secondaryAccent}
          >
            <AnchorLink id="services"></AnchorLink>
            <Heading>Find me</Heading>
            <FlexContainer alignItems="flex-start" justifyContent="center">
              <Teaser icon={faGithub} heading="GitHub">
                <a
                  href="https://github.com/issydennis"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  @issydennis
                </a>
              </Teaser>
              <Teaser icon={faLinkedin} heading="LinkedIn">
                <a
                  href="https://www.linkedin.com/in/isabelle-dennis-20323789/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Connect
                </a>
              </Teaser>
              <Teaser icon={faComment} heading="Blog">
                <a
                  href="https://www.frontendstumbles.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  frontendstumbles.com
                </a>
              </Teaser>
            </FlexContainer>
          </PageSection>

          <CustomPageSection background="white">
            <CustomBackgroundImage
              fluid={this.props.data.file.childImageSharp.fluid}
            >
              <AnchorLink id="about"></AnchorLink>
              <AboutBubble />
            </CustomBackgroundImage>
          </CustomPageSection>

          <PageSection
            align="center"
            background={props => props.theme.colors.lightFont}
          >
            <AnchorLink id="work"></AnchorLink>
            <Heading>My work</Heading>
            <FlexContainer>
              <PortfolioTeaser
                as={Link}
                to="/canine-craze"
                image={`url(${CanineCrazeImage})`}
                position="center top"
              >
                Canine Craze Ltd
              </PortfolioTeaser>
              <PortfolioTeaser
                as={Link}
                to="/behave-app"
                image={`url(${BehaveImage})`}
                position="center top"
              >
                Behave Web App
              </PortfolioTeaser>
              <PortfolioTeaser
                as={Link}
                to="/border-control"
                image={`url(${BorderControlImage})`}
                position="center top"
              >
                Border Control Gardens
              </PortfolioTeaser>
              <PortfolioTeaser
                as={Link}
                to="/carrot-joe"
                image={`url(${CarrotJoeImage})`}
                position="left center"
              >
                Carrot Joe Designs
              </PortfolioTeaser>
              <PortfolioTeaser
                as={Link}
                to="/aerotag"
                image={`url(${AeroTagImage})`}
                position="left center"
              >
                AeroTag
              </PortfolioTeaser>
              <PortfolioTeaser
                as="a"
                href="https://www.frontendstumbles.com"
                target="_blank"
                rel="noopener noreferrer"
                image={`url(${BlogImage})`}
                position="left center"
              >
                Front-End Stumbles Blog
              </PortfolioTeaser>
            </FlexContainer>
          </PageSection>

          <PageSection
            align="center"
            background={props => props.theme.colors.secondaryAccent}
          >
            <AnchorLink id="contact"></AnchorLink>
            <FlexContainer justifyContent="space-between">
              <CustomHeading>Let's chat</CustomHeading>
              <ContactText></ContactText>
            </FlexContainer>
          </PageSection>
        </>
      </ThemeProvider>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    file(name: { eq: "about-background" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
